<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 6"
        lg="5"
        md="6"
        sm="12"
      >
        <LastMonth />
      </v-col>
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 6"
        lg="5"
        md="6"
        sm="12"
      >
        <LastYear />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 6"
        lg="5"
        md="6"
        sm="12"
      >
        <Last10Years />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 6"
        lg="5"
        md="6"
        sm="12"
      >
        <CategoriesLastMonth />
      </v-col>
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 6"
        lg="5"
        md="6"
        sm="12"
      >
        <CategoriesLastYear />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 6"
        lg="5"
        md="6"
        sm="12"
      >
        <CategoriesLast10Years />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top20LastMonth />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top20LastYear />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top20Last10Years />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top20PGLastMonth />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top20PGLastYear />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top20PGLast10Years />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top10IPLastMonth />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
    <v-row class="mb-10">
      <v-spacer v-if="$isMobile()" />
      <v-col
        :cols="$isMobile()? 12 : 12"
        lg="12"
        md="12"
        sm="12"
      >
        <Top10IPLastYear />
      </v-col>
      <v-spacer v-if="$isMobile()" />
    </v-row>
  </v-container>
</template>

<script>
  import LastMonth from './statistics/LastMonth.vue'
  import Top20LastMonth from './statistics/Top20LastMonth.vue'
  import Top10IPLastMonth from './statistics/Top10IPLastMonth.vue'
  import Top20PGLastMonth from './statistics/Top20PGLastMonth.vue'
  import Top20LastYear from './statistics/Top20LastYear.vue'
  import Top10IPLastYear from './statistics/Top10IPLastYear.vue'
  import Top20PGLastYear from './statistics/Top20PGLastYear.vue'
  import Top20Last10Years from './statistics/Top20Last10Years.vue'
  import Top20PGLast10Years from './statistics/Top20PGLast10Years.vue'
  import CategoriesLastMonth from './statistics/CategoriesLastMonth.vue'
  import LastYear from './statistics/LastYear.vue'
  import Last10Years from './statistics/Last10Years.vue'
  import CategoriesLastYear from './statistics/CategoriesLastYear.vue'
  import CategoriesLast10Years from './statistics/CategoriesLast10Years.vue'
  export default {
    name: 'DashboardDashboard',
    components: { LastMonth, LastYear, Last10Years, CategoriesLastMonth, CategoriesLastYear, CategoriesLast10Years, Top20LastMonth, Top20LastYear, Top20Last10Years, Top20PGLastMonth, Top20PGLastYear, Top20PGLast10Years, Top10IPLastMonth, Top10IPLastYear },
    data () {
      return {
        dailySalesChart: {
          data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
            series: [
              [230, 750, 450, 300, 280, 240, 200, 190],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],

            ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headers: [
          {
            sortable: false,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Name',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Salary',
            value: 'salary',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Country',
            value: 'country',
            align: 'right',
          },
          {
            sortable: false,
            text: 'City',
            value: 'city',
            align: 'right',
          },
        ],
        items: [
          {
            id: 1,
            name: 'Dakota Rice',
            country: 'Niger',
            city: 'Oud-Tunrhout',
            salary: '$35,738',
          },
          {
            id: 2,
            name: 'Minerva Hooper',
            country: 'Curaçao',
            city: 'Sinaai-Waas',
            salary: '$23,738',
          },
          {
            id: 3,
            name: 'Sage Rodriguez',
            country: 'Netherlands',
            city: 'Overland Park',
            salary: '$56,142',
          },
          {
            id: 4,
            name: 'Philip Chanley',
            country: 'Korea, South',
            city: 'Gloucester',
            salary: '$38,735',
          },
          {
            id: 5,
            name: 'Doris Greene',
            country: 'Malawi',
            city: 'Feldkirchen in Kārnten',
            salary: '$63,542',
          },
        ],
        tabs: 0,
        tasks: {
          0: [
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: false,
            },
            {
              text: 'Create 4 Invisible User Experiences you Never Knew About',
              value: true,
            },
          ],
          1: [
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: false,
            },
          ],
          2: [
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
          ],
        },
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
