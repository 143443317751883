<template>
  <v-card
    elevation="8"
  >
        <v-card-title>
            Top 10 IP ultimo mese.
        </v-card-title>
  <GChart
    type="BarChart"
    :data="values"
    :options="chartOptions"
  />
  </v-card>
</template>
<script>
  import { GChart } from 'vue-google-charts'
  // const dateFormat = require('dateformat')
  const config = require('@/config')
  const axios = require('axios')
  export default {
    name: 'IPLastMonth',
    components: { GChart },
    data: () => ({
      values: [],
      homepage: 0,
      width: 10,
      radius: 4,
      padding: 8,
      lineCap: 'round',
      gradient: ['#f72047', '#ffd200', '#1feaea'],
      gradientDirection: 'top',
      fill: true,
      type: 'column',
      autoLineWidth: false,
      chartOptions: {
        chart: {
          title: 'Contatti ultimo mese',
          subtitle: 'Numero di contatti dell\'ultimo mese',
        },
        height: 800,
        colors: ['#003366'],
        opacity: [0.8],
        vAxis: {
          title: '',
          textStyle: {
            fontSize: 8,
            textAlign: 'right',
          },
        },
      },
    }),
    created: function () {
      var component = this
      var currentJWT = localStorage.getItem(config.authToken)
      // var payload = jwt.decode(currentJWT)
      axios
        .get(config.backendURL + '/stats/top10iplastmonth', {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          component.values = response.data.stats
          // component.values.splice(1, 1)
          // component.values[0].push({ role: 'style' })

          /*
          for (var i = 1; i < component.values.length; i++) {
            component.values[i][0] = dateFormat(new Date(component.values[i][0]), 'd')
            component.values[i].push('color #003366; opacity: 0.8; stroke-color: #0000ff')
          }
          */
          localStorage.setItem(config.authToken, response.data.jwt)
        })
        .catch(function (response) {
          console.log(response)
        })
    },
  }
</script>
