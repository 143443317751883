<template>
  <v-card
    elevation="8"
  >
  <GChart
    type="PieChart"
    :data="values"
    :options="chartOptions"
  />
        <v-card-title>
            Prevalenza categorie ultimo anno
        </v-card-title>
  </v-card>
</template>
<script>
  import { GChart } from 'vue-google-charts'
  const config = require('@/config')
  const axios = require('axios')
  export default {
    name: 'CategoriesLastYear',
    components: { GChart },
    data: () => ({
      values: [],
      width: 10,
      radius: 4,
      padding: 8,
      lineCap: 'round',
      gradient: ['#f72047', '#ffd200', '#1feaea'],
      gradientDirection: 'top',
      fill: true,
      type: 'column',
      autoLineWidth: false,
      chartOptions: {
        chart: {
          title: 'Contatti ultimo mese',
          subtitle: 'Numero di contatti dell\'ultimo mese',
        },
        colors: [],
        height: 300,
        is3D: true,
        opacity: [0.8],
        legend: { position: 'top', maxLines: 3 },
      },
    }),
    created: function () {
      var component = this
      var red = 0
      var green = 102
      var blue = 34
      for (var i = 0; i < 10; i++) {
        component.chartOptions.colors.push('#' + red.toString(16).padStart(2, '0') + green.toString(16).padStart(2, '0') + blue.toString(16).padStart(2, '0'))
        red += 10
        green += 10
        blue += 10
      }
      var currentJWT = localStorage.getItem(config.authToken)
      // var payload = jwt.decode(currentJWT)
      axios
        .get(config.backendURL + '/stats/categorieslastyear', {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          component.values = response.data.stats
          // component.values[0].push({ role: 'style' })

          /*
          for (var i = 1; i < component.values.length; i++) {
            component.values[i][0] = dateFormat(new Date(component.values[i][0]), 'd')
            component.values[i].push('color #003366; opacity: 0.8; stroke-color: #0000ff')
          }
          */
          localStorage.setItem(config.authToken, response.data.jwt)
        })
        .catch(function (response) {
          console.log(response)
        })
    },
  }
</script>
